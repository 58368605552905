// import { Box, Container, Grid } from "@mui/material";
// import React from "react";
// import { who_we_are } from "../iconsImports";

// const LandingPageWhoWeAre = () => {
//   return (
//     <Container maxWidth="lg" sx={{ marginTop: 1 }}>
//       <Grid container className="sectionBreake ">
//         <Grid lg={6} md={6} sm={12} xs={12} className="double-quots-mark-sm">
//           <div
//             className="landingPageHeadings animate__bounce"
//             style={{ textAlign: "left" }}
//           >
//             Who We are
//             <Box
//               style={{
//                 width: "60px",
//                 height: "10px",
//                 backgroundColor: "#dc5f5f",
//               }}
//             ></Box>
//           </div>
//           <div className="landing-bg_para ">
//             <Box
//               component="div"
//               className="shapedBg"
//               sx={{
//                 textAlign: "justify",
//               }}
//             >
//               We provide payment solutions for banking (new bank account
//               opening, cash deposits, cash withdrawals, emi deposits, credit
//               card bill payments, and indo-nepal remittances), communication
//               (prepaid & postpaid), entertainment (dth, ott), travel (bus,
//               train, and flight), insurance (general insurance, health, life,
//               term), and utilities for individuals, businesses, and corporations
//               (electric, water, fastag, gas). We work with banks, financial
//               institutions, and other service providers as associates. We use
//               one platform to deliver all of our services.
//             </Box>
//           </div>{" "}
//         </Grid>
//         <Grid lg={6} md={6} sm={12} xs={12}>
//           <img src={who_we_are} alt="who we are" width="100%"></img>
//         </Grid>
//       </Grid>
//     </Container>
//   );
// };

// export default LandingPageWhoWeAre;


import { Box, } from "@mui/material";
import React, { useEffect, useState } from "react";

const LandingPageWhoWeAre = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box sx={{ fontFamily: "'Poppins', sans-serif", color: "#FFFFFF" ,width:{lg:"100%",xs:"120%"}}}>
      {/* Hero Section */}
      <header
        style={{
          background: "linear-gradient(to right, #00927E, #011E41)",
          padding: "100px 20px",
          textAlign: "center",
          width: { lg: "100%" },
        }}
      >
        <h1 style={{ fontSize: "3.5rem", fontWeight: "700" }}>Who We Are</h1>
        <p style={{ fontSize: "1.5rem", marginTop: "10px", color: "#E0F2EF" }}>
          Empowering Your Financial Future
        </p>
        <p style={{ fontSize: "1.2rem", lineHeight: "1.8", marginTop: "20px" }}>
          We are a leading fintech company dedicated to transforming the
          financial industry with innovative technology and a customer-first
          approach. Our goal is to empower individuals and businesses by
          making financial services accessible, secure, and transparent.
        </p>
      </header>
    
      {/* Core Values Section */}
      <section style={{ padding: "60px 20px", backgroundColor: "#00927E" }}>
        <h2
          style={{
            textAlign: "center",
            fontSize: "2.5rem",
            fontWeight: "700",
            marginBottom: "40px",
          }}
        >
          Our Core Values
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          {[
            {
              title: "Innovation",
              text: "We continuously innovate to deliver cutting-edge solutions.",
              icon: "💡",
            },
            {
              title: "Trust",
              text: "Transparency and reliability are at the core of our services.",
              icon: "🤝",
            },
            {
              title: "Customer Focus",
              text: "Our customers' needs drive everything we do.",
              icon: "🎯",
            },
          ].map((value, index) => (
           <div
              key={index}
              style={{
                flex: "1 1 30%",
                backgroundColor: "#FFFFFF",
                color: "#011E41",
                padding: "20px",
                borderRadius: "10px",
                textAlign: "center",
                marginBottom: "20px",
                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
              }}
            >
 <div
                style={{
                  fontSize: "2.5rem",
                  marginBottom: "20px",
                }}
              >
                                {value.icon}
              </div>
              <h3
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "700",
                  marginBottom: "10px",
                }}
              >                {value.title}
              </h3>
              <p style={{ fontSize: "1rem", lineHeight: "1.5" }}>{value.text}</p>
            </div>
          ))}
        </div>
      </section>
    
      {/* Impact Section */}
      <section
        style={{
          padding: "60px 20px",
          textAlign: "center",
          backgroundColor: "#011E41",
        }}
      >
        <h2
          style={{
            fontSize: "2.5rem",
            fontWeight: "700",
            color: "#00927E",
            marginBottom: "40px",
          }}
        >
          Our Impact in Numbers
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          {[
            { value: "10K+", label: "Users worldwide" },
            { value: "₹10M", label: "Transactions processed" },
            { value: "500+", label: "Business clients" },
            { value: "99.9%", label: "Uptime guarantee" },
          ].map((stat, index) => (
            <div
              key={index}
              style={{
                flex: "1 1 22%",
                backgroundColor: "#00927E",
                color: "white",
                padding: "20px",
                borderRadius: "10px",
                marginBottom: "20px",
                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
              }}
            >
              <h3
                style={{
                  fontSize: "2.5rem",
                  fontWeight: "700",
                }}
              >
                {stat.value}
              </h3>
              <p style={{ fontSize: "1rem", marginTop: "10px" }}>
                {stat.label}
              </p>
            </div>
          ))}
        </div>
      </section>
    </Box>
    
  );
};

export default LandingPageWhoWeAre;
