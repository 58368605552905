import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";

const CommonCardDashBoard = ({ name, img, onClick }) => {
  return (
    <>
      <Tooltip title={name} placement="top">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            border: "4px solid",
            borderImage:
              "linear-gradient(135deg, #42a5f5 0%, #2196f3 50%, #1e88e5 100%) 1", // Blue gradient border
            borderRadius: "20px", // Rounded corners
            textAlign: "center",
            cursor: "pointer",
            transition: "all 0.3s ease-in-out",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            padding: { xs: "18px", sm: "18px" },
            boxShadow: "0 6px 18px rgba(33, 150, 243, 0.4)", // Subtle glow matching the gradient
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0 12px 24px rgba(33, 150, 243, 0.6)", // Intense glow on hover
            },
          }}
          onClick={onClick}
        >
          <Box
            sx={{
              width: "100px",
              height: "100px",
              background: "linear-gradient(135deg, #42e695 0%, #3bb2b8 100%)", // Vibrant green-blue gradient
              borderRadius: "50%", // Circular shape
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 10px 18px rgba(0, 0, 0, 0.25)", // Strong shadow
              mb: 2,
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "rotate(10deg) scale(1.1)", // Rotate slightly on hover
              },
            }}
          >
            <img
              src={img}
              alt={name}
              style={{
                width: "60px",
                height: "60px",
                objectFit: "cover",
              }}
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              textAlign: "center",
              color: "#ffffff", // White text for readability
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100%",
              fontSize: { xs: "14px", sm: "16px" },
              textShadow: "1px 1px 4px rgba(0, 0, 0, 0.6)", // Subtle shadow
              letterSpacing: "0.5px",
            }}
          >
            {name}
          </Typography>
        </Box>
      </Tooltip>
    </>
  );
};

export default CommonCardDashBoard;
