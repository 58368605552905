import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { useNavigate } from "react-router-dom";
import { primaryColor } from "../theme/setThemeColor";

const LandingPageWeOffer = () => {
  const navigate = useNavigate();
  const weOffer = [
    {
      icon: <MobileFriendlyIcon />,
      head: "BILL PAYMENT AND RECHARGE",
      para: "Get your prepaid mobile/tv/ott recharged instantly with earning opportunities on every transaction. Do the best recharge with updated plans & offers.",
    },
    {
      icon: <AccountBalanceIcon />,
      head: "BANKING",
      para: "We offer new account opening (Axis Bank), Indo-Nepal remittances, account deposit, withdrawal, balance enquiry, bulk transfer, payout solution. Mini ATM within a single app.",
    },
    {
      icon: <VolunteerActivismIcon />,
      head: "INSURANCE",
      para: "Get the best quote for your insurance requirements for life, health & vehicle insurance. Merchants can earn competitive commission on each policy booking.",
    },
    {
      icon: <ReceiptIcon />,
      head: "UTILITY",
      para: "Instantly update all your utility bill payments, including electricity, water & gas bills, credit card bills, EMI installments, and wallet top-ups.",
    },
  ];

  return (
    <Box
      className="whoWeAre_bg"
      sx={{
        background: "linear-gradient(to right, #00927E, #011E41)",
        color: "white",
        py: 8,
        width:{lg:"100%", xs:"120%",}
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography
              className="landingPageHeadings"
              variant="h2"
              sx={{ fontWeight: "bold", color: "#FFFFFF" }}
            >
              What We Offer
            </Typography>
            <Typography
              className="landingPageSubHeading"
              variant="h5"
              sx={{ mt: 2, color: "#FFFFFF" }}
            >
              A consumer-friendly solution for mobile recharge, money transfer, and bill payments
            </Typography>
            <Typography
              className="landingPagePara"
              variant="h6"
              sx={{ mt: 3, textAlign: "justify", color: "#E0E0E0" }}
            >
              Open a savings account, buy stocks and mutual funds, pay your bills, recharge, reserve
              flights and movie tickets, and much more. With us, anyone can get paid anywhere. Pay
              securely and without a card in person or online with the Paytm Wallet or directly from
              your bank account. You can also send and receive money from anyone.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              {weOffer.map((item, index) => (
                <Grid item md={6} lg={6} xs={12} key={index}>
                  <Card
                    sx={{
                      background: "#00927E",
                      borderRadius: "12px",
                      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                      p: 3,
                      transition: "all 0.3s ease",
                      "&:hover": {
                        transform: "translateY(-5px)",
                        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.4)",
                      },
                    }}
                  >
                    <CardContent sx={{ textAlign: "center" }}>
                      <Box
                        sx={{
                          width: "70px",
                          height: "70px",
                          margin: "0 auto",
                          mb: 2,
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#FFFFFF",
                          color: "#00927E",
                        }}
                      >
                        {item.icon}
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: "#FFFFFF", mb: 1 }}
                      >
                        {item.head}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "#D3D3D3", lineHeight: "1.6" }}
                      >
                        {item.para}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "center" }}>
                      <Button
                        onClick={() => navigate("/our-services")}
                        sx={{
                          fontWeight: "bold",
                          color: "#00927E",
                          backgroundColor: "#FFFFFF",
                          px: 3,
                          py: 1,
                          borderRadius: "8px",
                          "&:hover": {
                            backgroundColor: "#F1F1F1",
                          },
                        }}
                      >
                        Learn More
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LandingPageWeOffer;
