import { Box, Container, Grid, IconButton } from "@mui/material";
import React from "react";
import { PrimaryButton, SecondaryButton } from "../theme/Theme";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
// import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
// import { lp_illustration } from "../iconsImports";
import { useNavigate } from "react-router-dom";
import { getEnv, secondaryColor } from "../theme/setThemeColor";
import { useState } from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { lp_illustration } from "../iconsImports";
import AndroidIcon from '@mui/icons-material/Android';
const LandingPageIntro = () => {
  const navigate = useNavigate();
  const [env, setEnv] = useState(getEnv());
  return (
    <Box
      classname="landing-bg5"
      id="landing-intro"
      sx={{
        backgroundSize: { md: "cover", xs: "contain" },
        height: "max-content",
        background: "linear-gradient(to right, #00927E, #011E41)",
        // background-image: url(../assets/landing-page-assets/landingBg.png);
        // height: 100%;
        width: {lg:"100%",xs:"120%"} ,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgrounPosition: "left",
        mt: "-64px",

      }}
    >
      <Container maxWidth="xl">
        <Grid container xs={12} sx={{ px: { md: 8, sm: 1 } }}>
          <Grid
            md={env === "TrustPayIndia" || env === "PaisaKart" ? 6 : 12}
            sm={12}
          >
            <Box container sx={{ mt: 24 }}>
              <div className="lineUp">
                <div
                  className="landing-bg_main_font"
                  style={{ textAlign: env === "MoneyOddr" ? "center" : "left" }}
                >
                  Transform Your Journey Today with
                </div>
                <div className="landing-bg_biggpay_font">
                  {process.env.REACT_APP_TITLE === "MoneyOddr" ? (
                    <>
                      <span className="header_money_text"> Money</span>
                      <span
                        className="header_oddr_text"
                        style={{
                          color: secondaryColor(),
                          marginLeft: "10px",
                        }}
                      >
                        OddR
                      </span>
                    </>
                  ) : (
                    <div
                      style={{
                        fontSize: "3.4rem",
                        fontWeight: "bold",
                        fontFamily: "'Poppins', sans-serif",

                        background: "linear-gradient(90deg, #B23428, #011e41)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        letterSpacing: "1px",
                        textShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",

                        lineHeight: "1.5",
                        textAlign: "left",
                        transition: "all 0.3s ease",
                        mb: 2,
                      }}
                    >
                      {process.env.REACT_APP_TITLE}
                    </div>
                  )}
                </div>
              </div>
              <div className="lineUpDelay">
                {process.env.REACT_APP_TITLE === "MoneyOddr" ? (
                  <div className="mt-4">
                    <main className="landing_intro">
                      <p style={{ fontSize: "23px" }}>
                        An Emerging Digital Payment Platform For
                      </p>
                      <section className="animation mt-3">
                        <div className="animText">
                          <div>INDIVIDUALS</div>
                        </div>
                        <div className="animText">
                          <div>BUSINESSMEN</div>
                        </div>
                        <div className="animText">
                          <div>CORPORATIONS</div>
                        </div>
                      </section>
                    </main>
                  </div>
                ) : (
                  <div className="landing_intro">
                    Empowering connections through smooth, reliable, and
                    innovative payment solutions because growth begins with
                    trust.
                  </div>
                )}

                {/* {process.env.REACT_APP_TITLE !== "MoneyOddr" && (
                  <Box
                    component="div"
                    sx={{
                      textAlign: env === "MoneyOddr" ? "center" : "left",
                      mt: 1,
                      mb: 3,
                    }}
                  >
                    <IconButton
                      className="pulse-effect"
                      aria-label="delete"
                      sx={{
                        backgroundColor: "#fff",
                        marginRight: "0.5rem",
                        color: "#073980",
                      }}
                    >
                      <FacebookRoundedIcon />
                    </IconButton>
                    <IconButton
                      className="pulse-effect"
                      aria-label="delete"
                      sx={{
                        backgroundColor: "#fff",
                        marginRight: "0.5rem",
                        color: "#073980",
                      }}
                    >
                      <InstagramIcon />
                    </IconButton>
                    <IconButton
                      className="pulse-effect"
                      aria-label="delete"
                      sx={{
                        backgroundColor: "#fff",
                        marginRight: "0.5rem",
                        color: "#073980",
                      }}
                    >
                      <TwitterIcon />
                    </IconButton>
                  </Box>
                )} */}
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: {
                      lg: "row",
                      md: "row",
                      sm: "row",
                      xs: "column",
                    },
                    mb: 2,
                    mt: env === "MoneyOddr" ? 7 : 3,
                    alignItems: env === "MoneyOddr" ? "center" : "left",
                    justifyContent: env === "MoneyOddr" ? "center" : "left",
                  }}
                >
                  <PrimaryButton
                    variant="contained"
                    size="small"
                    sx={{
                      mr: { xs: env === "MoneyOddr" ? 0 : 2, md: 2 },
                      mb: { md: 0, xs: 2 },
                      mt: 2,
                    }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Explore Now
                    <ArrowForwardIcon sx={{ ml: 1 }} />
                  </PrimaryButton>
                  <SecondaryButton
                    variant="contained"
                    size="large"
                    className="button-red"
                    sx={{mt:2,p:1}}
                    href={
                      env === "MoneyOddr"
                        ? "https://drive.google.com/drive/folders/1TYFavBc1EVWyt60sB7-p0o2p7YSNsXZE?usp=drive_link"
                        : "https://drive.google.com/drive/folders/1TYFavBc1EVWyt60sB7-p0o2p7YSNsXZE?usp=drive_link"
                    }
                    target="_blank"
                    startIcon={ <AndroidIcon />}
                  >
                    Download App
                  </SecondaryButton>
                </Box>
              </div>
            </Box>
          </Grid>
          {env !== "MoneyOddr" && (
            <Grid
              md={6}
              sm={12}
              sx={{
                display: { xs: "none", sm: "none", md: "block" },
                mt: 15,
                mb: 5,
              }}
            >
              <img
                src={lp_illustration}
                alt="illustration"
                width="85%"
                height="auto"
                style={{
                  backgroundColor: getEnv() === "PaisaKart" ? "#fff" : "",
                  borderRadius: getEnv() === "PaisaKart" ? "50%" : "",
                }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default LandingPageIntro;
